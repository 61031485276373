<template>
  <div>
    <b-card v-if="!(eventData && eventCategories && eventTypes && companies && fieldGroups)" class="text-center">
      <b-row>
        <b-col cols="12">
          <div class="text-center">
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <div>
      <event-edit-content :event-data="eventData" :field-groups="fieldGroups" :event-categories="eventCategories" :event-types="eventTypes" :companies="companies" v-if="eventData && eventCategories && eventTypes && companies && fieldGroups" />
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BSpinner } from 'bootstrap-vue'
import EventEditContent from './EventEditContent.vue'
import moment from 'moment'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BSpinner,

    EventEditContent
  },

  data() {
    return {
      eventData: {
        id: null,
        name: null,
        banner_url: null,
        banner_blob: null,
        nametag_url: null,
        nametag_blob: null,
        logo_url: null,
        logo_blob: null,
        file_blob: null,
        file_url: null,
        date_start: null,
        date_end: null,
        start_registration_date: null,
        end_registration_date: null,
        description: null,
        location: null,
        event_type_id: null,
        event_category_id: null,
        facebook_link: null,
        instagram_link: null,
        linkedin_link: null,
        web_link: null,
        success_register_message: null,
        price: null,
        company_id: null,
        itineraries: null,
        sliders: null,
        sliders_blob: null,
        date: null,
        registration_date: null,
        fields: null,
        use_payment_gateway: true,

        font_style: null,
        font_color: null,
        font_size: null,
        company_first: null,
        font_blob: null,
        font_url: null,

        question_id: null
      },
      eventCategories: null,
      eventTypes: null,
      companies: null,
      fieldGroups: null,
      questions: null
    }
  },

  methods: {
    formatDateReverse(value) {
      return moment(String(value), 'YYYY-MM-DD').format('DD-MM-YYYY')
    },
    fetchQuestions() {
      this.$http.get('/admin/v1/questions')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.questions = []

            currentData.forEach((item, index) => {
              this.questions.push({
                label: item.name,
                value: item.id
              })
            }) 
          }
       })
    },
    fetchFieldGroups() {
      this.$http.get('/admin/v1/field_groups')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.fieldGroups = []

            currentData.forEach((item, index) => {
              this.fieldGroups.push({
                name: item.name,
                fields: item.fields
              })
            }) 
          }
       })
    },
    fetchEventCategories() {
      this.$http.get('/admin/v1/event_categories')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.eventCategories = []

            currentData.forEach((item, index) => {
              this.eventCategories.push({
                label: item.category,
                value: item.id
              })
            })

            
          }
      })
    },
    fetchCompanies() {
      this.$http.get('/admin/v1/companies')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.companies = []

            currentData.forEach((item, index) => {
              this.companies.push({
                label: item.name,
                value: item.id
              })
            })
            
          }
      })
    },
    fetchEventTypes() {
      this.$http.get('/admin/v1/event_types')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.eventTypes = []

            currentData.forEach((item, index) => {
              this.eventTypes.push({
                label: item.type,
                value: item.id
              })
            })
            
          }
      })
    },
  },
  created() {
    this.fetchCompanies()
    this.fetchEventCategories()
    this.fetchEventTypes()
    this.fetchFieldGroups()
    // this.fetchQuestions()
  },
}
</script>